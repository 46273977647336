import {
  SIDE_NAV_LIGHT,
  NAV_TYPE_SIDE,
  DIR_LTR,
} from "constants/ThemeConstant";
import { env } from "./EnvironmentConfig";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

export const APP_NAME = "LAMA";
export const API_BASE_URL = env.API_ENDPOINT_URL;

//LAMA STORE RELATED
export const ESHOP_URL = env.ESHOP_URL;

export const IS_PAAGE = process.env.REACT_APP_PAAGE === "true";

//YEARLY DISCOUNT
export const MILD_YEARLY_DISCOUNT = 0.05;
export const SPICY_YEARLY_DISCOUNT = 0.1;

//MINIMUM MARGIN
export const MINIMUM_MARGIN_PERCENTAGE = 0.15;
export const MINIMUM_MARGIN_AMOUNT = 3;

//PLAN PRICE
export const MILD_MONTHLY_PRICE = 14.99;
export const SPICY_MONTHLY_PRICE = 29.99;
export const MILD_YEARLY_PRICE = 119.88;
export const SPICY_YEARLY_PRICE = 239.88;

//COLLECTIONS
export const FRAMES_COLLECTION_ID =
  env.FRAME_COLLECTION_ID || "pcol_01GCE54DAZN5A3K9JCCG339K13";
// TODO: here the env var is not PAPERS_COLLECTION_ID, is it normal ?
// i guess it's not set in .env, and it takes the fallback value below
export const PAPERS_COLLECTION_ID =
  env.FRAME_COLLECTION_ID || "pcol_01GCE3GXXDBHJD6S394PJNNWJH";
export const MATS_COLLECTION_ID =
  env.MATS_COLLECTION_ID || "pcol_01GCKZQV0FQHTN3BTTQBAQQJEY";
export const READY_TO_FRAMES_COLLECTION_ID =
  process.env.REACT_APP_READY_TO_FRAMES_COLLECTION_ID ||
  "pcol_01GX3BAF9GRCYH2CH3G0WCSNGA";

// dev pcol_01GX3BAF9GRCYH2CH3G0WCSNGA
//prod pcol_01GX924N371PB6AY0DJZNJDJYM

export const GOODIES_VARIANT_ID = "";

//PAPER SETTINGS
export const HAHNEMÜHLE_PAPER_ID = "prod_01GCE4KD68T5SW815BFGMA41ZR";
export const CANSON_PAPER_ID = "prod_01GE23W9H1H8EXR1RVG5GSDGG4";
export const SIHL_PAPER_ID = "prod_01GE2995SVEJBN9G00W2BTX2TC";
export const CANVA_PAPER_ID = "prod_01H7CY0HCZNTQRFY6Z8N49H6DF";
export const POSTER_PAPER_ID = "prod_01GV0P55VEC3B39T8TWFSWVRF1";

//FRAME SETTINGS
export const LIGHT_PINK_GALLERY_FRAME_ID = "prod_01GKKR8149TCYMMEFJ65DWEYDC";
export const TURQUOISE_BLUE_GALLERY_FRAME_ID =
  "prod_01GKKQ7SYB3MHNF0PRG6V1W;SWG";
export const ELECTRIC_BLUE_GALLERY_FRAME_ID = "prod_01GKKPY2DEF31SSR0RK96YMD75";
export const INTENSE_RED_GALLERY_FRAME_ID = "prod_01GKKNMVH4EX09KJRZ32N1RCT4";
export const SUNNY_YELLOW_GALLERY_FRAME_ID = "prod_01GKKMZ8HEJA1AMDSZT0FDRZHJ;";
export const GRASS_GREEN_GALLERY_FRAME_ID = "prod_01GKKJA7N47ZSW9HJVBQR16GHH";
export const ROUNDED_GOLD_FRAME_ID = "prod_01GCE8ZP84YRDDC99YW1KJN7ZM";
export const WHITE_GALLERY_FRAME_ID = "prod_01GCE8Y7T4JTEFYCB3AG5P0V84;";
export const BLACK_GALLERY_FRAME_ID = "prod_01GCE8K3HSPK56N6H3QGTN21Y0";
export const OAK_GALLERY_FRAME_ID = "prod_01GCE809JTNA77E29BBSE2DCPD";

// READY TO FRAME SETTINGS
export const READY_TO_FRAME_DEFAULT_ID = "prod_01GX3BTW1FXDBBFSAFPP7Y4VHV";
// prod_01GX928E5D9QNX5DD7BPWTEJEX prod_01GX928E5D9QNX5DD7BPWTEJEX

// MAT SETTINGS
export const BLACK_MAT_ID = "prod_01GCM0R8MRTTAFQMDBS4Y7ZK6Z";
export const WHITE_MAT_ID = "prod_01GCM0E3JEFD1KT4J065MPMVN3";

//DEFAULT PRINT SETTINGS
export const PAPER_FORMAT_DEFAULT = env.PAPER_FORMAT_DEFAULT || "A4";
export const PRINT_PRODUCT_ID_DEFAULT =
  env.PRINT_PRODUCT_ID_DEFAULT || "prod_01GCE4BM12X9X5TTFV1FM24RZ7";
export const PRINT_VARIANT_ID_DEFAULT =
  env.PRINT_VARIANT_ID_DEFAULT || "variant_01GCE4BM9P4CK466XJGPAR0NFK";

//DEFAULT FRAME SETTINGS
export const FRAME_FORMAT_DEFAULT = env.FRAME_FORMAT_DEFAULT || "A3";
export const FRAME_COLOR_DEFAULT = env.FRAME_COLOR_DEFAULT || "oak";

//DEFAULT MAT SETTINGS
export const MAT_COLOR_DEFAULT = env.MAT_COLOR_DEFAULT || "White";

//DEFAULT GLASS SETTINGS
export const GLASS_PRODUCT_ID_DEFAULT = env.GLASS_PRODUCT_ID_DEFAULT || "";
export const GLASS_VARIANT_ID_DEFAULT = env.GLASS_VARIANT_ID_DEFAULT || "";

export const DEFAULT_SHIPPING_PROFILE =
  process.env.REACT_APP_DEFAULT_SHIPPING_PROFILE ||
  "sp_01GCE4BM12X9X5TTFV1FM24RZ7";

export const DEFAULT_REGION_ID =
  process.env.REACT_APP_DEFAULT_REGION_ID || "reg_01GC71Q06KY5NTEXRJ8MR7W50J";

//STRIPEKEY
export const STRIPE_PUBLIC_KEY =
  env.STRIPE_PUBLIC_KEY ||
  "pk_live_51HKQLWFc5r6Q3WLxAw5BS7UZfHma8BhTaZAgsOaQ4MRW65CN3bUMcT4EZ7Kt0GxWXLLryibNcKUspQtAOzRZYWbs00w5IeXk4O";

//DPI
export const DPI_DANGER = env.DPI_DANGER || 170;
export const DPI_RECO = env.DPI_RECO || 203;
export const DPI_BEST = env.DPI_BEST || 300;

//UPLOAD CHUNK SIZE
export const CHUNK_SIZE = env.CHUNK_SIZE || 1024 * 1024 * 5;
export const MAX_UPLOAD_SIZE_MO = env.MAX_UPLOAD_SIZE_MO || 100;
export const MAX_SIZE_UPLOAD_SIZE_BYTES =
  MAX_UPLOAD_SIZE_MO * 1024 * 1024 || 1024 * 1024 * 100;

export const THEME_CONFIG = {
  navCollapsed: false,
  sideNavTheme: SIDE_NAV_LIGHT,
  locale: "en",
  navType: NAV_TYPE_SIDE,
  topNavColor: "#3e82f7",
  headerNavColor: "",
  mobileNav: false,
  currentTheme: "light",
  direction: DIR_LTR,
  studioNav: false,
};

export const DELIVERY_TIME = {
  eu: {
    print_only: {
      a0: "3-5",
      a1: "3-5",
      a2: "3-5",
      a3: "3-5",
      a4: "3-5",
      a5: "3-5",
      a6: "3-5",
    },
    framed: {
      "50x50": "5-7",
      "30x30": "5-7",
      "50x70": "5-7",
      "60x80": "5-7",
      "70x100": "5-7",
      "40x60": "5-7",
      "40x50": "5-7",
      "30x40": "5-7",
      a3: "5-7",
      a4: "5-7",
      a5: "5-7",
    },
  },
  ch: {
    print_only: {
      a0: "3-5",
      a1: "3-5",
      a2: "3-5",
      a3: "3-5",
      a4: "3-5",
      a5: "3-5",
      a6: "3-5",
    },
    framed: {
      "50x50": "5-7",
      "30x30": "5-7",
      "50x70": "5-7",
      "60x80": "5-7",
      "70x100": "5-7",
      "40x60": "5-7",
      "40x50": "5-7",
      "30x40": "5-7",
      a3: "5-7",
      a4: "5-7",
      a5: "5-7",
    },
  },
  "us-ca": {
    print_only: {
      a0: "3-5",
      a1: "3-5",
      a2: "3-5",
      a3: "3-5",
      a4: "3-5",
      a5: "3-5",
      a6: "3-5",
    },
    framed: {
      "50x50": "5-7",
      "30x30": "5-7",
      "50x70": "5-7",
      "60x80": "5-7",
      "70x100": "5-7",
      "40x60": "5-7",
      "40x50": "5-7",
      "30x40": "5-7",
      a3: "5-7",
      a4: "5-7",
      a5: "5-7",
    },
  },
  "cn-domtom": {
    print_only: {
      a0: "3-5",
      a1: "3-5",
      a2: "3-5",
      a3: "3-5",
      a4: "3-5",
      a5: "3-5",
      a6: "3-5",
    },
    framed: {
      "50x50": "5-7",
      "30x30": "5-7",
      "50x70": "5-7",
      "60x80": "5-7",
      "70x100": "5-7",
      "40x60": "5-7",
      "40x50": "5-7",
      "30x40": "5-7",
      a3: "5-7",
      a4: "5-7",
      a5: "5-7",
    },
  },
  row: {
    print_only: {
      a0: "5-7",
      a1: "5-7",
      a2: "5-7",
      a3: "5-7",
      a4: "5-7",
      a5: "5-7",
      a6: "5-7",
    },
    framed: {
      "50x50": "7-10",
      "30x30": "7-10",
      "50x70": "7-10",
      "60x80": "7-10",
      "70x100": "7-10",
      "40x60": "7-10",
      "40x50": "7-10",
      "30x40": "7-10",
      a3: "7-10",
      a4: "7-10",
      a5: "7-10",
    },
  },
};
